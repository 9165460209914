@import '../scss/variables';

.OrderSummary {
  .order-items {
    tr,
    th,
    td {
      background-color: transparent;
    }
    thead th,
    thead td {
      border: 0;
    }
    .photo-container {
      line-height: 1.2;
      margin: 5px 0;
    }
    .thumbnail {
      width: 60px;
      height: 50px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .price {
      text-align: right;
    }
  }
  .summary-props {
    max-width: 220px;
  }
  .summary-quantity {
    max-width: 160px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    input {
      margin-right: 5px;
    }
  }

  .price-details {
    text-align: right;
    .total-price {
      font-size: 33px;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 40px;

    .price-details {
      margin-left: 10px;
      margin-right: 10px;
    }
    .order-items {
      th,
      td {
        padding: 5px;
        min-width: 30px;
        font-size: 12px;
      }
      .unstyled-button {
        padding-left: 5px;
      }
      .mr-4 {
        margin-right: 10px !important;
      }
    }
  }
}
