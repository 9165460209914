.icon-button-wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .icon-background {
    position: absolute;
    top: 8px;
    left: calc(100% - 30px - 8px);
    z-index: 1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;

    img {
      width: 14px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
  .label {
    display: flex;
    width: 100px;
    margin-left: 9px;
  }
}
