.PhotoshootDetailsPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .container-fluid {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .toolbar-text {
    font-weight: 400;
    font-size: 16px;
  }
}
