$toolbar-height: 50px;

.BottomToolbar {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 0px;
  transition: height 0.1s linear;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
  padding: 0px 15px;

  &.visible {
    height: $toolbar-height;
    button:not([disabled]) {
      opacity: 1;
    }
  }

  .children {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  button {
    opacity: 0;
    height: $toolbar-height;
    padding: 0px 20px;
    font-size: 12px;
    line-height: 12px;
    transition: opacity 0.1s linear;
  }
}
