@import '../../scss/variables';

.PaginationRow {
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
    .page-size-selector {
      flex-basis: calc(50% - 100px);
      select {
        width: 100px;
      }
    }
  }

  button {
    color: $color-primary;
  }
  .page-item {
    &.active .page-link {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
      color: white;
    }
    .page-link {
      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: white;
      }
      &:focus {
        box-shadow: 0 0 0 1px lighten($color-primary, 40%);
      }
    }
  }
  li {
    margin-left: 10px;
  }
  span {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
  }
}
