@import './variables';

.total-price-container {
  line-height: 1;

  strong {
    font-size: 13px;
    display: block;
    color: $color-text-dim;
  }

  .total-price {
    font-size: 44px;
  }
}

.unstyled-button {
  border: 0;
  padding: 0 0 0 10px;
  margin: 0;
  background: none;
}

// XXX: just have these here for a lack of a better space
.photocolor-sepia {
  //filter: sepia(.6) grayscale(.3);
  //filter: sepia(1) hue-rotate(-20deg);
  filter: sepia(1) contrast(80%) hue-rotate(-20deg);
}
.photocolor-bw {
  filter: grayscale(95%);
}

.modal-dialog {
  &.generic-modal,
  &.category-modal {
    max-width: 80vw;
    min-width: 500px;
    display: flex;
    justify-content: center;
    .modal-content {
      width: 100%;
      max-width: 1000px;
    }
    .modal-body {
      width: 100%;
      div {
        display: flex;
        justify-content: center;
      }
    }
  }
  &.generic-modal {
    max-width: fit-content;
    img {
      max-height: 80vh;
    }
  }
}
