@import '../scss/variables';

.modal-dialog.generic-modal {
  .modal-body {
    .product-info {
      > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .more-information {
        div {
          display: flex;
          flex-direction: column;
        }
      }
      .gallery-block {
        display: grid;
        gap: 10px;
        img {
          width: 465px;
          max-height: 100%;
        }
      }
    }
  }
}
