@import '../../scss/variables';

.AdminLayout {
  h1 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-text-dim;
  }

  h2,
  h3,
  h4 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
  }

  .btn-primary,
  button[type='submit'] {
    font-size: 13px;
  }

  .button-row {
    display: flex;
    justify-content: space-between;
  }

  .FilterWithoutOrders {
    margin-bottom: 10px;
  }
}

.height-100 {
  height: 100%;
}

.property-list-form {
  .checkbox-wrapper {
    margin-top: 10px;
  }
}

/* Oma PSV style override for bootstrap pagination */
.row .react-bootstrap-table-pagination {
  .page-item {
    &.active .page-link {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
      color: white;
    }
    .page-link {
      color: $color-primary;
      text-decoration: none;
      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: white;
      }
      &:focus {
        box-shadow: 0 0 0 1px lighten($color-primary, 40%);
      }
    }
  }
}

.free-product-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .remove {
    border: 0;
    padding: 0px;
    margin: 0;
    background: none;
    flex-shrink: 0;
  }
}

@media print {
  .AdminLayout {
    .admin-sidebar-container {
      display: none;
    }
    .admin-main {
      flex: 0 0 100%;
      max-width: 100%;
    }
    a::after {
      content: '';
    }
    .admin-page-group-header {
      display: none;
    }
  }
  .SiteFooter {
    display: none;
  }
}
