@import './variables.scss';
@import './globalComponents.scss';

.primary-font {
  font-family: 'Montserrat', sans-serif;
}

body {
  @extend .primary-font;
  color: $color-text;
  background-color: $color-gray-lighter;
}

hr {
  border-top: 0;
  border-bottom: 1px solid $color-gray-medium;
}

.text-muted {
  color: $color-text-dim;
}

.form-control {
  color: $color-text;
}

select.form-control {
  outline: none;
  appearance: none;
  background-image: url(../img/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 50%;
  padding-right: 20px !important;

  option {
    color: $color-text;
  }
  option:disabled,
  &:invalid {
    color: $color-text-dim;
  }
}

input.form-control {
  height: 38px;
}

input.form-control,
textarea.form-control {
  background-color: white;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: normal;
  @extend .primary-font;

  &::placeholder {
    font-style: normal;
    color: $color-text-dim;
  }
}

.btn {
  border-radius: 0;
  border: 0;
  padding: 1.5em 0.75em;
  text-transform: uppercase;
  font-size: 13px;

  &[type='submit'] {
    font-size: 13px;
  }
}
.btn-primary {
  background-color: $color-primary;
  &:hover,
  &:active {
    background-color: $color-primary-dark;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
  }
  &:focus {
    background-color: $color-primary-dark;
    border-color: #cc0007;
    box-shadow: 0 0 0 0.2rem rgb(165, 60, 60);
  }
  &:disabled {
    background-color: $color-primary;
    cursor: not-allowed;
  }
}

.btn-link {
  @extend .primary-font;
  color: $color-primary;
  text-decoration: underline;
  &:hover,
  &:visited {
    color: $color-primary;
  }

  &.btn {
    padding: 0;
    text-transform: none;
    font-size: inherit;
  }
}

.nav {
  a {
    text-decoration: none;
  }

  & > li {
    margin-left: inherit;
  }
}

.modal-title {
  margin: inherit;
}

.form-group label + input[type='checkbox'].custom {
  margin-left: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.full-width {
  width: 100%;
}
