@import '../scss/variables';

.SiteHeader {
  @media screen and (min-width: $screen-lg-min) {
    z-index: 500;
    height: 65px;

    .navbar-wrapper {
      position: fixed;
      width: 100%;
      background-color: white;
    }
  }

  a,
  a:visited {
    color: $color-text;
    text-decoration: none;
    font-size: 13px;
  }

  .navbar {
    padding-top: 0;
    padding-bottom: 0;

    text-transform: uppercase;
    font-size: 13px;
    background-color: white;

    .navbar-nav .nav-link {
      color: $color-text;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .Logo {
    height: 34px;
    margin-left: -5px;
  }

  @media (min-width: $screen-lg-min) {
    .navbar {
      height: 65px;

      .navbar-collapse,
      .navbar-nav,
      .nav-link,
      .nav-item {
        height: 100%;
      }

      .nav-link,
      .nav-item {
        display: flex;
        align-items: center;
      }

      .nav-left {
        .nav-link,
        .nav-item {
          padding-left: 25px;
          padding-right: 25px;
        }
      }

      .navbar-nav {
        .highlighted {
          padding-left: 75px;
          padding-right: 75px;
          border-left: none;

          color: white;
          background-color: $color-primary;
          transition: background-color 0.2s;

          &:hover {
            color: white;
            background-color: $color-primary-dark;
          }

          & + .nav-link,
          & + .nav-item {
            border-left: none;
          }
        }

        .shopping-cart {
          .badge {
            position: absolute;
            transform: translateX(12px) translateY(-10px);
            border-radius: 100%;
            padding: 0.25em 0.5em;
          }
        }
      }
    }

    .nav-right {
      .nav-link {
        border-left: 2px solid $color-gray-light;
        min-width: 78px;
        text-align: center;
        justify-content: center;
      }
    }

    #user-dropdown + .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}
