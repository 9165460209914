.heart-icon .icon-background img {
  margin-left: 0;
  margin-bottom: -2px;
}

.heart-icon.heart-icon-inline {
  .icon-background {
    background-color: transparent;
    position: initial;
  }
}
