@import '../scss/variables';

.SecondaryHeader {
  background-color: $color-tertiary-dark;
  color: white;
  font-size: 13px;
  height: 42px;
  display: flex;
  align-items: center;

  &.margin-bottom {
    margin-bottom: 20px;
  }

  ol {
    text-transform: uppercase;
    margin: 0;
    list-style-type: none;

    li {
      display: inline;
      margin-left: 0;
    }

    li + li::before {
      content: url('../img/icons/chevron-right.svg');
      margin-left: 15px;
      margin-right: 15px;
      display: inline-block;
      transform: translateY(-2px);
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    height: auto;
    min-height: 42px;
    padding-top: 5px;
    padding-bottom: 5px;

    .checkbox-wrapper {
      .checkbox-children {
        flex-shrink: 1;
      }
    }
  }

  .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
