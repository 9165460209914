@import '../scss/variables';

.PhotoGrid {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  &.item-size-small {
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }

  &.item-size-medium {
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }

  @media screen and (min-width: $screen-xxl-min) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: $screen-xs-max) {
    grid-template-columns: repeat(2, 1fr);

    &.item-size-small {
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
    }

    &.item-size-medium {
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
  }

  & > li {
    margin: 0;
    padding: 0;
  }
}
