@import '../../scss/variables';

.PhotoDisplay {
  height: 100%;
  .image-container {
    width: 100%;
    height: 100%;
    background-color: $color-tertiary-dark;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    position: relative;

    &.fullscreen {
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      z-index: 501;
    }

    &:hover {
      .photo-switcher {
        opacity: 0.5;
      }
    }

    .photo-switcher {
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      width: 100px;
      background-repeat: no-repeat;
      background-position: 50%;

      opacity: 0;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &.previous {
        left: 0;
        background-image: url(../../img/icons/chevron-rounded-left.svg);
      }

      &.next {
        right: 0;
        background-image: url(../../img/icons/chevron-rounded-right.svg);
      }
    }
  }
  .image-controls {
    display: flex;

    .icon-button-wrapper {
      width: 38px;
      height: 30px;
      .icon-background {
        left: 8px;
      }
    }
  }
  .photo-code {
    height: 30px;
    margin-top: 8px;
    margin-left: 8px;
    padding: 0px 10px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }
}
