@import '../scss/variables';

.SubmitOrderPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > .container-fluid {
    flex-grow: 1;
  }

  .ThankYou {
    flex-grow: 1;
  }

  .tos {
    color: $color-tertiary-med;
  }

  @media (min-width: $screen-lg-min) {
    & > .container-fluid {
      display: flex;
      flex-direction: row;
    }

    .shopping-cart-container {
      flex-basis: 1fr;
      flex-grow: 1;
      margin-right: 60px;
    }

    .order-info-container {
      background-color: white;
      flex-basis: 400px;
      margin-top: -20px;
      margin-right: -15px;
      padding: 20px 30px 30px;
    }
  }
}
