.SharePhotoshootModal {
  .title {
    font-size: 16px;
  }
  .subtitle {
    color: #b8b8b8;
    font-size: 13px;
  }
  .delete-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
