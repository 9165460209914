@import '../scss/variables';

.PhotoCompare {
  padding: 22px 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .photo-item {
    flex-grow: 1;
    flex-basis: 350px;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;

    .top-row {
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .PhotoDisplay {
      flex-grow: 1;
    }

    .bottom-row {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .btn-secondary {
    height: 36px;
    padding: 0px 20px;
    text-transform: initial;
    background-color: #dedede;
    color: black;
  }
}
