@import '../scss/variables';

.ShoppingCartSidebar {
  $width: 435px;
  position: fixed;
  z-index: 1000;
  border-top: 1px solid $color-gray-medium;
  top: 65px;
  bottom: 0;
  right: 0;
  width: $width;
  background-color: white;
  transition: right 0.2s, visibility 0s 0s;
  display: flex;
  flex-direction: column;

  &.closed {
    pointer-events: none;
    right: -$width;
    // we must hide it so that it cannot be tabbed into
    // 1s here means that when the class becomes closed, it takes 1s to hide it,
    // but (because parent selector has 0s) when it becomes open, it shows instantly
    visibility: hidden;
    transition: right 0.2s, visibility 0s 1s;
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
  }

  h3 {
    padding: 20px 10px;
    border-bottom: 1px solid $color-gray-medium;
    margin: 0;
  }

  .items {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    list-style-type: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    margin: 0;
    border-bottom: 1px solid $color-gray-medium;
    line-height: 1;
    gap: 20px;

    .remove {
      border: 0;
      padding: 0 0 0 10px;
      margin: 0;
      background: none;
    }

    .details {
      flex-grow: 1;

      .photo-code {
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .thumbnail {
      width: 60px;
      height: 50px;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .price {
      padding-right: 10px;
    }
  }

  .retouching-fee-container {
    margin: 20px 10px;
    text-align: right;
  }
  .total-price-container {
    margin: 20px 10px;
    text-align: right;
  }

  footer {
  }

  @media screen and (max-width: $screen-md-max) {
    top: 0;
  }
  @media screen and (max-width: $screen-sm-max) {
    top: 0;
    width: 100%;

    &.closed {
      right: -100%;
    }
  }
}
