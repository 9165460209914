@import '../scss/variables';

.Thumbnail {
  margin: 0;

  .image-container {
    width: 100%;
    background: #fefefe;
    padding-bottom: 56.35%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      height: auto;
    }
  }

  .bottom-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-group {
      display: flex;
      align-items: center;
      div:not(:last-child) {
        margin-right: 14px;
      }
    }
  }

  figcaption {
    display: inline-block; // prevent wrapping link text-decoration
    color: $color-text;
    text-decoration: none;
    font-size: 16px;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;

    small {
      display: block;
      font-size: 1em;
      color: $color-text-dim;
      line-height: 16px;
    }
  }

  &.size-small {
    figcaption {
      max-width: 90px;
    }
  }

  &.square {
    .image-container {
      padding-bottom: 100%;
    }
    figcaption {
      max-width: 200px;
    }
  }

  &.original {
    .image-container {
      padding-bottom: 0;
      background-color: #e6e6e6;

      img {
        position: static;
        top: auto;
        left: auto;
        width: auto;
        display: block;
        margin: 0 auto;
        transform: none;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &.background-dark {
    .image-container {
      background-color: $color-tertiary-dark;
    }
  }

  .thumbnail-favourite img {
    width: 10px;
    height: 10px;
  }
}
