.checkbox-wrapper {
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .tick {
      background-color: white;
      &:after {
        display: block;
      }
    }
  }
  &:hover input[type='checkbox'] ~ .tick {
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
  }
  .checkbox-slot {
    width: 22px;
    height: 22px;
    position: relative;
  }
  .checkbox-children {
    margin-left: 5px;
  }

  .tick {
    position: absolute;
    top: 0;
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 5px;
    border: 1.5px solid #161615;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7.5px;
      top: 3px;
      width: 6px;
      height: 12px;
      border: solid black;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  > div {
    flex-shrink: 0;
    font-size: 13px;
  }
}
