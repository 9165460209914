$color-text: #111;
$color-text-dim: #b8b8b8;

$color-primary: #9e1b20;
$color-primary-dark: darken($color-primary, 5%);
$color-secondary: #111;
$color-tertiary-dark: #111;
$color-tertiary-med: #999;
$color-tertiary-light: #ddd;

$color-gray-light: #e5edf0;
$color-gray-lighter: #fafafa;
$color-gray-medium: #dedede;

$screen-xs-min: 0;
$screen-xs-max: 575px;

$screen-sm-min: 576px;
$screen-sm-max: 767px;

$screen-md-min: 768px;
$screen-md-max: 991px;

$screen-lg-min: 992px;
$screen-lg-max: 1199px;

$screen-xl-min: 1200px;
$screen-xl-max: 1920px;

$screen-xxl-min: 1921px;
