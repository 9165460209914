@keyframes UploadPreviewThumbnail-image-uploading {
  // LOL @ this animation
  from {
    border-image-source: linear-gradient(0deg, #007bff, #fff);
  }
  6.25% {
    border-image-source: linear-gradient(22.5deg, #007bff, #fff);
  }
  12.5% {
    border-image-source: linear-gradient(45deg, #007bff, #fff);
  }
  18.75% {
    border-image-source: linear-gradient(67.5deg, #007bff, #fff);
  }
  25% {
    border-image-source: linear-gradient(90deg, #007bff, #fff);
  }
  31.25% {
    border-image-source: linear-gradient(112.5deg, #007bff, #fff);
  }
  37.5% {
    border-image-source: linear-gradient(135deg, #007bff, #fff);
  }
  43.75% {
    border-image-source: linear-gradient(157.5deg, #007bff, #fff);
  }
  50% {
    border-image-source: linear-gradient(180deg, #007bff, #fff);
  }
  56.25% {
    border-image-source: linear-gradient(202.5deg, #007bff, #fff);
  }
  62.5% {
    border-image-source: linear-gradient(225deg, #007bff, #fff);
  }
  68.75% {
    border-image-source: linear-gradient(247.5deg, #007bff, #fff);
  }
  75% {
    border-image-source: linear-gradient(270deg, #007bff, #fff);
  }
  81.25% {
    border-image-source: linear-gradient(292.5deg, #007bff, #fff);
  }
  87.5% {
    border-image-source: linear-gradient(315deg, #007bff, #fff);
  }
  93.75% {
    border-image-source: linear-gradient(337.5deg, #007bff, #fff);
  }
  to {
    border-image-source: linear-gradient(360deg, #007bff, #fff);
  }
}
.UploadPreviewThumbnail {
  border: 2px solid transparent;
  padding: 3px;

  &.uploading {
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #007bff, #fff);
    animation: UploadPreviewThumbnail-image-uploading 1s infinite;
  }

  &.uploaded {
    border-color: #3db53d;
  }

  &.error {
    border-color: #b53d3d;
  }

  .Thumbnail figcaption {
    margin-top: 5px;
    font-size: smaller;
  }
}
