@import '../scss/variables';
@import '../scss/mixins';

.LoginPage {
  @include full-height;
  width: 100vw;
  display: flex;
  flex-direction: row;

  a {
    text-decoration: none;
  }

  .LoginPage-image {
    background-image: url('../img/login-bg.jpg');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    flex-basis: 50%;
  }

  .LoginPage-form-wrapper {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;

    form {
      width: 100%;
      max-width: 450px;
      text-align: center;

      .Logo {
        margin: 0 auto 22px;
        max-width: 250px;
      }
    }
  }

  .Router {
    display: contents;
  }

  @media screen and (max-width: $screen-xs-max) {
    flex-direction: column;

    .LoginPage-image {
      display: none;
    }

    .LoginPage-form-wrapper {
      flex-basis: 100%;
      flex-grow: 1;
      padding: 20px;
    }

    // Alternative style
    //.LoginPage-image {
    //  display: block;
    //  flex-basis: 25%;
    //}
    //.LoginPage-form-wrapper {
    //  padding-top: 0px;
    //  padding-bottom: 25%;

    //  form {
    //    margin-top: -20px;
    //  }
    //}
  }
}
