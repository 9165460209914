.ProductsPage {
  .spinner,
  .error {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .error {
    font-weight: bold;
    font-size: 2em;
  }
  #banner {
    background-image: url('../img/kuvatuotteet_2.jpg');
  }
}
