.Collapsible {
  .header {
    cursor: pointer;
  }
  .header::after {
    display: inline-block;
    margin-left: 10px;
    transition: transform 0.1s linear;
  }
  &.open {
    & > .header::after {
      content: url(../../img/icons/chevron-down.svg);
      transform: rotate(-180deg) translateY(-3px);
    }
  }
  &.closed {
    & > .header::after {
      content: url(../../img/icons/chevron-down.svg);
    }
  }
}
