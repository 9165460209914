@import '../../scss/variables';

.PhotoUploader {
  .files {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    margin: 0;
    width: 100%;
    position: relative;
    background-color: white;

    .header {
      position: absolute;
      width: 100%;
      pointer-events: none;
      text-align: center;
      padding-top: 50px;

      .icon img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }
    }

    input {
      padding: 200px 0 50px;
      width: 100%;
      text-align: center;
    }
  }

  .preview {
    padding: 5px 20px 20px 20px;
    background-color: white;
    margin: 20px 0;
  }
}
