@import '../scss/variables';

:root {
  // HACK: Chrome/webkit doesn't stretch the grid area properly even though top row has 1fr.
  // So we stretch this element to accomplish the same thing (mostly)
  // See:
  // https://stackoverflow.com/questions/57378851/grid-rows-do-not-stretch-to-full-element-height-if-parent-has-flex-direction-col
  // Take the viewport height and subtract SiteHeader, SecondaryHeader (breadcrumbs), footer, and 10px for safety
  --sidebar-height: calc((var(--vh, 1vh) * 100) - 65px - 62px - 45px - 10px);
}

.PhotoDetails {
  display: grid;
  flex-grow: 1;
  grid-template-areas:
    'side photo'
    'side thumbs';
  grid-template-rows: 1fr min-content;
  grid-template-columns: auto 1fr;
  height: 100%;
  min-height: 100%;

  & > .PhotoDisplay {
    grid-area: photo;
    align-self: stretch; // is this needed?
    padding-bottom: 10px;
  }

  .image-container {
    width: 100%;
    height: 100%;
    background-color: $color-tertiary-dark;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    position: relative;

    &:hover {
      .photo-switcher {
        opacity: 0.5;
      }
    }

    .photo-switcher {
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      width: 100px;
      background-repeat: no-repeat;
      background-position: 50%;

      opacity: 0;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &.previous {
        left: 0;
        background-image: url(../img/icons/chevron-rounded-left.svg);
      }

      &.next {
        right: 0;
        background-image: url(../img/icons/chevron-rounded-right.svg);
      }
    }
  }

  & > .sidebar {
    grid-area: side;
    width: 300px;
    padding-right: 40px;
    margin-top: 0;

    min-height: var(--sidebar-height);
    max-height: var(--sidebar-height);
    overflow-y: auto;
  }

  .sidebar-image-container {
    img {
      max-width: 100%;
    }
  }

  & > .thumbnails {
    grid-area: thumbs;
    margin: -2px;
    padding: 2px;
    list-style-type: none;
    overflow-y: auto;
    overflow-x: visible;
    max-height: 20vh;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    gap: 10px;

    & > li {
      padding: 0;
      width: auto;
      display: inline-block;
      margin: 0 0 0 0;
    }

    .Thumbnail {
      &.selected {
        .image-container {
          outline: 2px solid $color-tertiary-dark;
        }
      }
      figcaption {
        margin-top: 2px;
        font-size: smaller;
        color: $color-tertiary-med;
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    display: flex;
    flex-direction: column;

    & > .PhotoDisplay {
      margin-left: -15px; // container x-padding
      margin-right: -15px; // container x-padding
      margin-top: -20px; // secondary header margin-bottom
      min-height: 400px;

      & > .image-container {
        min-height: 500px;
      }
    }
    & > .sidebar {
      //border-top: 1px solid $color-gray-medium;
      //margin-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      border-bottom: 1px solid $color-gray-medium;
      margin-bottom: 20px;
      min-height: 0;
    }

    & > .thumbnails {
      max-height: none;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 40px;
    }
  }
}
