.Rating {
  line-height: 1;
  flex-shrink: 0;

  .star,
  .remove {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .star {
    width: 14px;
    background-position: right;
    height: 14px;
    background-image: url(../img/icons/star.svg);

    & + .star {
      width: 14px + 5px;
    }

    &.filled {
      background-image: url(../img/icons/star-filled.svg);
    }
  }

  .remove {
    width: 10px;
    height: 10px;
    background-image: url(../img/icons/cross.svg);
    margin-left: 7px;
    visibility: hidden;
  }

  &.editable {
    .star,
    .remove {
      cursor: pointer;
    }

    &:hover {
      .remove {
        visibility: visible;
      }
    }
    //&:hover {
    //  .star {
    //    background-image: url(../img/icons/star-filled.svg);
    //  }
    //}
    //.star:hover {
    //  background-image: url(../img/icons/star-filled.svg);

    //  & ~ .star {
    //    background-image: url(../img/icons/star.svg);
    //  }
    //}
  }
}
