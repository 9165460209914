.MainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .Router {
      display: contents;
      //display: flex;
      //flex-grow: 1;
      //flex-direction: column;
      //width: 100%;
    }
  }
}
