.PhotoshootToolbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .left-side {
    flex-grow: 1;
    height: 100%;
    min-width: 280px;
    min-height: 45px;
  }

  .right-side {
    display: flex;
    flex-grow: 1;
    height: 45px;
    min-width: 280px;
    justify-content: flex-end;
    align-items: center;
  }
}
