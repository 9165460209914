@import '../scss/mixins';

.LoadingIndicator.fullscreen {
  @include full-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
